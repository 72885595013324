import { template as template_8d7ca1fd6f8c4c62a45c9a69f77708c7 } from "@ember/template-compiler";
import NotificationsTracking from "discourse/components/notifications-tracking";
import { i18n } from "discourse-i18n";
const CategoryNotificationsTracking = template_8d7ca1fd6f8c4c62a45c9a69f77708c7(`
  <NotificationsTracking
    @onChange={{@onChange}}
    @levelId={{@levelId}}
    @showCaret={{@showCaret}}
    @showFullTitle={{@showFullTitle}}
    @prefix="category.notifications"
    @title={{i18n "category.notifications.title"}}
    class="category-notifications-tracking"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default CategoryNotificationsTracking;
