import { template as template_980ce89947c746f8a0b92815d4ca3674 } from "@ember/template-compiler";
const WelcomeHeader = template_980ce89947c746f8a0b92815d4ca3674(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
