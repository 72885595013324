import { template as template_9cd7888a078c46d598933293b339ae3d } from "@ember/template-compiler";
const FKLabel = template_9cd7888a078c46d598933293b339ae3d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
