import { template as template_9a8b8305cfe14bfa84b24d894061c858 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_9a8b8305cfe14bfa84b24d894061c858(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
